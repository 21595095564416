export function formatNumber(value: number | Date | string, decimals?: number) {
  if (!isFinite(+value)) return '-'
  return (+value).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}

const currencies: { [key: string]: string } = {
  EUR: '€',
  USD: '$',
  GBP: '£',
}

export function getCurrencySymbol(key: string) {
  return currencies[key] || '€'
}

export function formatCurrency(value: number, key: string) {
  const symbol = getCurrencySymbol(key)
  return isFinite(value) ? symbol + '' + formatNumber(value, 2) : '-'
}

export function formatDate(value: Date | number | null | string) {
  if (value == null || value === 0) return '-'
  const d = new Date(value)
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}

export function formatPercent(value: number) {
  if (!isFinite(value)) return '-'
  return Number(value).toLocaleString('en', { maximumFractionDigits: 2 }) + '%'
}
