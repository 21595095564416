export function addDays(date: Date, days: number): Date {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export function isBefore(date: Date, dateToCompare: Date): boolean {
  return date.getTime() < dateToCompare.getTime()
}

export function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime())
}

export function formatDistanceStrict(date: Date, baseDate: Date): string {
  const diff = (date.getTime() - baseDate.getTime()) / 1000
  const unitMapping = [
    [60 * 60 * 24 * 365, 'year'],
    [60 * 60 * 24 * 30, 'month'],
    [60 * 60 * 24 * 7, 'week'],
    [60 * 60 * 24, 'day'],
    [60 * 60, 'hour'],
    [60, 'minute'],
    [1, 'second'],
  ] as const

  for (const [secondsInUnit, unitName] of unitMapping) {
    if (Math.abs(diff) >= secondsInUnit) {
      const value = Math.round(diff / secondsInUnit)
      const formatter = new Intl.RelativeTimeFormat()
      return formatter.format(value, unitName)
    }
  }

  return '0 seconds'
}

export function startOfHour(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours())
}

export function startOfWeek(date: Date): Date {
  const result = new Date(date)
  result.setDate(date.getDate() - date.getDay())
  return result
}

export function startOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function dateSet(date: Date, values: { year?: number; month?: number; date?: number }): Date {
  const result = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  if (values.year) result.setFullYear(values.year)
  if (values.month || values.month === 0) result.setMonth(values.month)
  if (values.date) result.setDate(values.date)
  if (values.month || values.month === 0) result.setMonth(values.month)
  return result
}

export function isSameYear(date?: Date, dateToCompare?: Date): boolean {
  if (!date || !dateToCompare) return false

  return date.getFullYear() === dateToCompare.getFullYear()
}

export function isSameMonth(date: Date, dateToCompare: Date): boolean {
  return isSameYear(date, dateToCompare) && date.getMonth() === dateToCompare.getMonth()
}

export function isSameDay(date: Date, dateToCompare: Date): boolean {
  return isSameMonth(date, dateToCompare) && date.getDate() === dateToCompare.getDate()
}

export function isEqual(date: Date, dateToCompare: Date): boolean {
  return date && dateToCompare && date.getTime() === dateToCompare.getTime()
}

export function addYears(date: Date, years: number): Date {
  const result = new Date(date)
  result.setFullYear(result.getFullYear() + years)
  return result
}

export function addMonths(date: Date, months: number): Date {
  const result = new Date(date)
  result.setMonth(result.getMonth() + months)
  return result
}

export function addSeconds(date: Date, seconds: number): Date {
  const result = new Date(date)
  result.setSeconds(result.getSeconds() + seconds)
  return result
}
