import { createI18n } from 'vue-i18n'
import en from './translations/en.json'
import ja from './translations/ja.json'

const locale = navigator.language.split('-')[0]

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  locale,
  fallbackLocale: 'en',
  // fallbackWarn: false,
  // missingWarn: false,
  messages: { en, ja },
  datetimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
    ja: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  },
})
