import { createApp } from 'vue'
import { crashPlugin } from '@madxnl/dodo-ui'
import { createPinia } from 'pinia'
import { hotJarPlugin } from './hotjar'
import { router } from './router'
import { sentryPlugin } from './sentry'
import { extensionPlugin } from './extension'
import App from './app/App.vue'
import './style.css'
import '@madxnl/dodo-ui/dist/style.css'
import './variables.css'
import { i18n } from './i18n'

window.location.href.includes('socialdatabase.com') ? document.body.classList.add('h-full') : undefined

const pinia = createPinia()
const app = createApp(App)

app.use(i18n)
app.use(router)
app.use(sentryPlugin)
app.use(extensionPlugin)
app.use(hotJarPlugin)
app.use(pinia)
app.use(crashPlugin({ router }))

app.mount('#app')
