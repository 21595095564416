<template>
  <ProgressBar :value="api.progressValue.value" />
  <component :is="pageLayout" v-if="pageLayout" />
  <RouterView v-else />
  <AppMessages />
</template>

<script lang="ts" setup>
import { onErrorCaptured, onMounted, ref, watch, defineAsyncComponent, shallowRef } from 'vue'
import { RouteMeta, RouterView, useRouter } from 'vue-router'
import { RequestError, api } from '../api'
import settings from '../settings'
import { useRequireLogin, useUserLanguage } from '../auth'
import ProgressBar from '../base/ProgressBar.vue'
import AppMessages from './AppMessages.vue'
import { messageService } from './messageService'

const isDev = settings.MODE === 'development'
const login = useRequireLogin()
const goLogin = ref(false)

useUserLanguage()

const router = useRouter()

const mappedRoutes: Record<RouteMeta['layout'], any> = {
  'pro-targeting': defineAsyncComponent(() => import('../pro-targeting/layout/PageLayout.vue')),
  'deep-thought': defineAsyncComponent(() => import('../deep-thought/layout/PageLayout.vue')),
  none: undefined,
  dashboard: defineAsyncComponent(() => import('../base/layout/PageLayout.vue')),
  'dashboard-no-nav': defineAsyncComponent(() => import('../base/layout/PageLayout.vue')),
}

const pageLayout = shallowRef()
watch(router.currentRoute, async (n, o) => {
  if (n.meta?.layout && n.meta.layout !== o.meta.layout) {
    pageLayout.value = mappedRoutes[n.meta.layout]
  }
})

onMounted(async () => {
  await router.isReady()
  document.title =
    router.currentRoute.value.meta.layout === 'pro-targeting'
      ? 'Targeting Toolkit'
      : router.currentRoute.value.meta.layout === 'deep-thought'
        ? 'Deep Thought'
        : 'Socialdatabase'
  if (isDev) {
    /* eslint-disable no-console */
    console.info('Developer documentation for Dodo UI framework can be found here:\nhttps://madxnl.github.io/dodo-ui/')
  }
})

watch(goLogin, (value) => {
  if (value) return login.redirectToLogin()
})

onErrorCaptured((err) => {
  // Handle login required errors
  if (err instanceof RequestError && err.response.status === 403) {
    goLogin.value = true
    return false // prevent error from bubbling up
  }
  // Handle uncaught form submission errors (bad request)
  if (err instanceof RequestError && err.response.status === 400) {
    const msg = err.message.slice(0, 300)
    messageService.error(`Failed to submit form: ${msg}`)
    return false // prevent error from bubbling up
  }
})
</script>
