const settings = {
  API_ROOT: 'https://api.socialdatabase.com',
  API_ROOT_DEEPTHOUGHT: 'https://api.xdeepthought.com',
  FB_CLIENT_ID: '1735018493414860',
  FB_SCOPE: 'email,public_profile,ads_management,ads_read,pages_show_list',
  GOOGLE_APP_ID: '301033914355-msp0h3hvriqm3c342vilm3earjns0ego.apps.googleusercontent.com',
  SENTRY_URL: 'https://15c591be2f1740d5b38d7ed4eae09cd4@o389230.ingest.sentry.io/5535367',
  LINKEDIN_CLIENT_ID: '78d053vvk5eqs5',
  LINKEDIN_SCOPE: 'r_emailaddress r_ads w_organization_social rw_ads r_basicprofile r_liteprofile r_ads_reporting r_organization_social w_member_social',
  MODE: 'production' as 'development'|'test'|'production',
  DASHBOARD_VERSION: process.env.DASHBOARD_VERSION,
  MIXPANEL_TOKEN: 'f69bfdfeb90027ef635c084a086275a6',
  PRO_TARGETING_AUTH_TOKEN: '4bfb11572da119d9628b7ff46a3927997869c0e2',
  EXTENSION_AUTH_TOKEN: 'dd9752fd56e42815d464545b24f4ba2703377a3d',
  HOTJAR_ID: '2719785',
  HOTJAR_SNIPPET_VERSION: '6',
  SENTRY_AUTH_TOKEN: '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',
}

export default settings

const missing = Object.entries(settings).filter(([k, v]) => (v as unknown) === undefined).map(([k, v]) => k)
if (missing.length) throw new Error('Missing env variables: ' + missing.join(', '))
