import { computed, reactive, ref, toRefs } from 'vue'
import {
  api,
  AdAccount,
  GrantedAccount,
  Team,
  TeamMember,
  TeamPublic,
  UserAdAccount,
  schema,
  TeamInput,
} from '../api'
import { currentUser, refreshCurrentUser, isAudienceAdmin } from '../auth'

const data = reactive({
  myTeams: [] as (Team | TeamPublic)[],
  currentSlug: null as string | null,
  availableAccounts: [] as AdAccount[],
})

const currentTeam = ref<Team | null>()

export const teamService = reactive({
  ...toRefs(data),

  currentTeam,

  currentTeamIds: computed(() => (currentTeam.value ? [currentTeam.value.id] : [])),

  async loadUserTeams() {
    data.myTeams = await api.call(schema.UserTeam.list)
    const found = data.myTeams?.find((x) => x.id === currentTeam.value?.id)
    if (found) currentTeam.value = found as any
  },

  async setCurrentTeam(slug?: string) {
    if (slug !== currentTeam.value?.slug) {
      const team = data.myTeams.find((t) => t.slug === slug)
      if (slug && team) {
        data.currentSlug = slug
        currentTeam.value = team as any
        localStorage.setItem('recentTeam', slug!)
      } else {
        data.currentSlug = null
        currentTeam.value = null
      }
      await this.loadCurrentTeamData()
    }
  },

  async getTeamForHostname(): Promise<TeamPublic | undefined> {
    const hostname = window.location.hostname
    const team = hostname.split('.')[0]
    if (['dashboard', 'local', 'staging', 'localhost'].includes(team)) {
      return {
        id: 0,
        name: 'Local',
        slug: 'local',
        logo: '',
        member_count: 1,
      }
    } else {
      try {
        return await this.getPublicTeamInfo(team)
      } catch (e: any) {
        if (e.response.status === 404) {
          return undefined
        }
      }
    }
  },

  async getPublicTeamInfo(teamSlug: string) {
    return (await api.get(`/api/teaminfo/${teamSlug}/`)) as TeamPublic
  },

  deserializeTeam(input: object) {
    const result = { ...input } as Team

    return result
  },

  async getHelperDocuments() {
    const result = await api.call(schema.HelperDocuments.list)

    return result
  },

  async getHelperDocumentLink(document_id?: number, name?: string) {
    const query: Record<string, string | number> = {}
    if (document_id) query['id'] = document_id
    if (name) query['name'] = name

    const response = await api.call(schema.HelperDocuments.download, { query })
    const link = document.createElement('a')
    link.setAttribute('href', response.url)
    link.setAttribute('download', 'file.pdf')
    link.setAttribute('target', '_blank')
    link.click()
  },

  async getTeamMembers(params = {}) {
    const result = await api.get(`/api/teams/${teamService.currentSlug}/members`)
    return result as TeamMember[]
  },

  async getTeamInvites(params = {}) {
    if (!teamService.currentSlug) {
      return
    }

    const result = await api.call(schema.TeamInvite.list, { path: { team_id: teamService.currentSlug } })
    return result
  },

  async createInvite(invite: { invited_email: string; message?: string }) {
    if (!teamService.currentSlug) {
      return
    }

    await api.call(schema.TeamInvite.create, { path: { team_id: teamService.currentSlug }, body: invite })
  },

  async acceptInvite(teamSlug: string, code: string) {
    const result = await api.call(schema.TeamInvite.accept, { path: { team_id: teamSlug }, body: { code } })
    return result
  },

  getAccountById(accountId: string | null): AdAccount | null {
    const acc = data.availableAccounts.find((a) => a.id === accountId) as AdAccount | undefined
    return acc || null
  },

  async loadCurrentTeamData() {
    if (!currentUser.value) {
      data.availableAccounts = []
    }

    if (!currentTeam.value) {
      data.availableAccounts = []
      return
    }

    const slug = currentTeam.value.slug
    data.availableAccounts = await api.get(`/api/teams/${slug}/accounts/`)
    data.availableAccounts.sort((a, b) => a.name!.localeCompare(b.name!))
  },

  getTeamAccount(accountId: string) {
    return data.availableAccounts.find((a) => a.id === accountId)
  },

  async createTeam(data: TeamInput, grantAccounts: UserAdAccount[]) {
    const team = await api.call(schema.UserTeam.create, { body: data })
    await refreshCurrentUser()
    await Promise.all(grantAccounts.map((acc) => this.grantAccount(acc.id, team.id)))
    await teamService.loadUserTeams()
    return team
  },

  async getGrantedAccounts() {
    const results = await api.call(schema.GrantedAdAccount.list)
    return results
  },

  getTeamGrantedAccounts() {
    return data.availableAccounts
  },

  async revokeAccount(memberAccount: GrantedAccount) {
    await api.call(schema.GrantedAdAccount.destroy, { path: { pk: memberAccount.id } })
    await teamService.loadCurrentTeamData()
  },

  async grantAccount(accountId: string, teamId: number) {
    const member = currentUser.value!.memberships.find((m) => m.team === teamId)!.id
    await api.call(schema.GrantedAdAccount.create, { body: { member, account: { account_id: accountId, network: 'twitter' } } })
    await teamService.loadCurrentTeamData()
  },

  userIsCurrentTeamAdmin() {
    const teamId = currentTeam.value?.id
    return currentUser.value?.memberships.some((m) => m.team === teamId && m.level === 'admin')
  },

  teamHasBillingDetails() {
    const team = currentTeam.value
    if (!team) return false
    return team.invoice_email && team.country && team.invoice_name
  },
})

/** Get the current active plan from active team's subscriptions */
export function useTeamSubscription() {
  const teamSubscription = computed(() => {
    const team = teamService.currentTeam
    const subs = team?.subscriptions ?? []
    subs.sort((a, b) => b.start.localeCompare(a.start))
    return subs.length ? subs[0] : null
  })
  const hasActiveSub = computed(() => {
    return !!teamSubscription.value?.active || isAudienceAdmin.value
  })

  const hasEnterpriseSubscription = computed(
    () => !!teamSubscription.value?.plan.name.toLowerCase().includes('enterprise'),
  )

  return { teamSubscription, hasActiveSub, hasEnterpriseSubscription }
}
