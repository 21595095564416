import { getCurrencySymbol } from '../stats/utils'
import { formatDistanceStrict, isValidDate } from './date'

type Network = 'facebook' | 'twitter' | 'linkedin'

// round and format for display (31331.2 => '31,331')
const numberLocaleStringDecimals = (value: number, decimals: number) =>
  (+value).toLocaleString('nl', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

export function numberFormat(value: number, decimals = 0, round = 0) {
  let result = value
  if (round !== 0) {
    const length = String(Math.abs(value)).length - round + 1
    result = Math.round(value / Math.pow(10, length)) * Math.pow(10, length)
  }
  return numberLocaleStringDecimals(result, decimals)
}

export function listToCapitalisedString(list: string[]) {
  return list.map((s) => (s[0] ? s[0].toUpperCase() + s.slice(1) : s)).join(', ')
}

export function currencyFormat(value: number, currency: string) {
  const symbol = getCurrencySymbol(currency)
  if (!isFinite(+value)) return '-'
  return symbol + ' ' + numberLocaleStringDecimals(value, 2)
}

export function capitalize(str: string | null): string {
  if (typeof str !== 'string') return '' + str
  str = str.trim()
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

export function lower(str: string) {
  return typeof str === 'string' ? str.toLowerCase() : str
}

export function truncate(str: string | null, length = 15) {
  if (typeof str !== 'string') return str
  if (str.length <= length) return str
  return str.slice(0, length - 1) + '…'
}

export function dateFormat(date: Date | string | null | undefined) {
  if (!date) return ''
  if (isValidDate(new Date(date))) {
    return new Date(date).toLocaleDateString('en', { day: 'numeric', month: 'short', year: 'numeric' })
  }
  return date
}

export function dateTimeFormat(date: Date | string) {
  if (isValidDate(new Date(date))) {
    return new Date(date).toLocaleDateString('en', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }
  return date
}

export function debounce(callback: () => void | Promise<void>, duration = 500) {
  let timeout: ReturnType<typeof setTimeout>
  return async () => {
    clearTimeout(timeout)
    await new Promise((resolve) => {
      timeout = setTimeout(resolve, duration)
    })
    await callback()
  }
}

export const htmlDecode = (() => {
  const textarea = document.createElement('textarea')
  return (str: string) => {
    if (typeof str !== 'string') return str
    textarea.innerHTML = str
    return textarea.value
  }
})()

export function localeDate(date: Date | string | number, options?: Intl.DateTimeFormatOptions) {
  if (!date) return date
  return new Date(date).toLocaleDateString(navigator.language, options)
}

export function localeDatetime(date: Date | string | number, options?: Intl.DateTimeFormatOptions) {
  if (!date) return date
  return new Date(date).toLocaleString(navigator.language, options)
}

export function numberWithThousandsSeparator(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function localeNumber(number: number | string, decimals = 0) {
  if (!isFinite(Number(number))) return number
  return (+number).toLocaleString(navigator.language, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}

export function isDigits(value?: string | number) {
  if (!value) return false

  return /^-?\d+$/.test(value.toString())
}

export function relativeTime(date: Date | string | number, compareTo = new Date()) {
  if (!date) return date
  return formatDistanceStrict(new Date(date), compareTo)
}

export function capitalizePlatform(network: Network) {
  const name = {
    twitter: 'Twitter',
    facebook: 'Facebook',
    linkedin: 'LinkedIn',
  }[network.toLowerCase()]
  return name || network
}

export function humanizeObjective(s: string) {
  if (typeof s !== 'string') return s
  s = s.replace(/_/g, ' ')
  s = s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()
  return s
}

export function humanizeVar(s: string) {
  if (typeof s !== 'string') return s
  return s
    .replace(/(_|-|\s)+/g, ' ')
    .replace(/([A-Z])(?=[a-z])/g, (c) => ' ' + c.toLowerCase())
    .replace(/([a-z])(?=[A-Z])/g, (c) => c + ' ')
    .trim()
    .replace(/^[a-z]/, (c) => c.toUpperCase())
}

export function formatBigNum(num: number) {
  if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M'
  if (num >= 1e3) return (num / 1e3).toFixed(0) + 'K'
  return num.toString()
}
