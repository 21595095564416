import { computed, nextTick, ref, watch } from 'vue'
import { api, RequestError, schema, User, UserAdAccount, UserInput } from '../api'
import settings from '../settings'
import { teamService } from '../team'
import { messageService } from '../app'
import { i18n } from '@/i18n'
import { useMixpanel } from '@/tracking'

const user = ref<User>()
const userAccounts = ref<UserAdAccount[]>([])
const oauthStateSessionKey = 'oauth-state'
const { track, register, reset, identify } = useMixpanel()

watch(
  user,
  (u) => {
    if (u) api.setHeader('X-CSRFToken', u.token)
  },
)

// Allow read-only external access to state
export const currentUser = computed(() => user.value)
export const userAdAccounts = computed(() => userAccounts.value)
export const isAudienceAdmin = computed(() => {
  let groups = [] as string[]
  if (currentUser.value?.groups) {
    groups = currentUser.value.groups.map((x) => x.name)
    return !!groups.includes('Audience_creator')
  } else {
    return false
  }
})
export const isSDBTeam = computed(() => {
  return teamService.currentTeam?.name.toLowerCase().includes('socialdatabase')
})

export const fullUsername = computed(() => {
  return (user.value?.first_name ? user.value.first_name + ' ' + user.value.last_name : user.value?.email ?? '').trim()
})

export async function checkAuthenticated() {
  if (!currentUser.value) {
    await refreshCurrentUser()
  }
  return currentUser.value
}

export async function redirectSocialLogin(network: 'twitter' | 'google', forceLogin = false) {
  // Save a random state param used for CSRF protection
  const returnUri = `${location.protocol}//${location.host}/login/${network}`
  const state = crypto.getRandomValues(new Uint32Array(1))[0].toString()
  sessionStorage.setItem(oauthStateSessionKey, state)

  let href: string
  if (network === 'twitter') {
    // Twitter doesn't support oauth2 so we must include state param it in callback URI
    const oauth_callback = `${returnUri}?state=${state}`
    const { oauth_token } = await api.call(schema.TwitterRequestTokenView.get, { query: { oauth_callback } })
    href =
      `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}` +
      `&oauth_callback=${encodeURIComponent(oauth_callback)}` +
      (forceLogin ? '&force_login=true' : '')
  } else {
    // google
    href =
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${settings.GOOGLE_APP_ID}` +
      `&scope=email` +
      '&response_type=code' +
      `&state=${state}` +
      `&redirect_uri=${encodeURIComponent(returnUri)}`
  }
  window.location.replace(href!)
  await new Promise((resolve) => setTimeout(resolve, 20000))
}

export async function finalizeSocialLogin(network: 'twitter' | 'google', params: any) {
  // Validate the state key to prevent CSRF attacks
  const state = sessionStorage.getItem(oauthStateSessionKey)
  sessionStorage.removeItem(oauthStateSessionKey)
  if (state == null || state !== params.state) {
    throw new Error('Error: Unauthorized login attempt')
  }
  delete params.state

  const connect = !!sessionStorage.getItem('connectSocial')
  sessionStorage.removeItem('connectSocial')

  if (network === 'twitter') {
    track('User Logged In', { type: 'X' })
    params = await api.get('/rest-auth/twitter/access_token/', params)
  }

  if (connect) {
    // 'force' CSRF token
    await refreshCurrentUser()
    await api.post(`/rest-auth/${network}/connect/`, params)
  } else {
    await api.post(`/rest-auth/${network}/`, params)
  }
  await refreshCurrentUser()
}

export async function connectSocial(network: 'twitter', returnPath: string) {
  localStorage.setItem('returnPath', returnPath)
  sessionStorage.setItem('connectSocial', 'true')
  await redirectSocialLogin(network, true)
}

export async function signOut(reload: boolean = true): Promise<void> {
  track('User Logged Out', { type: 'socialdatabase' })
  reset()
  await api.call(schema.LogoutView.post)
  sessionStorage.clear()
  localStorage.clear()
  if (reload) {
    window.location.reload()
  }
}

export async function refreshCurrentUser() {
  try {
    user.value = await api.call(schema.User.me)
    if (user.value) {
      identify(user.value.email)
      if (user.value.social_accounts?.some((x) => x.provider === 'twitter')) {
        register({ x_account_ids: user.value.social_accounts.filter((x) => x.provider === 'twitter').map((x) => x.id) })
      }
      if (user.value.memberships) {
        register({ team_ids: user.value.memberships.map((x) => x.team) })
      }
      register({ id: user.value.id })
    }
  } catch (err: unknown) {
    // HTTP 403 forbidden means no valid auth session to resume, user is not logged in
    if (err instanceof RequestError && err.response.status === 403) {
      reset()
      user.value = undefined
      userAccounts.value = []
      return
    }
    throw err
  }
  await teamService.loadUserTeams()
  userAccounts.value = await api.call(schema.UserAdAccount.list)
}

export async function disconnectSocialAccount(id: string | number) {
  await api.call(schema.SocialAccountDisconnectView.post, { path: { pk: id }, body: {} })
  await refreshCurrentUser()
}

export function useUserLanguage() {
  const { availableLocales, locale } = i18n.global
  watch(() => user.value?.language, updateLang, { immediate: true })
  const { geoLocation, fetchLocation } = useGeolocation()
  fetchLocation()
  const navigatorLocale = navigator.language.split('-')[0]

  const japaneseUser = computed(
    () =>
      locale.value.includes('ja') ||
      navigatorLocale.includes('ja') ||
      (geoLocation.value && geoLocation.value.country == 'JP'),
  )

  async function updateLang() {
    if (user.value) {
      const userLang = user.value?.language
      // set ui language from user preference
      if (userLang && availableLocales.includes(userLang) && locale.value !== userLang) {
        locale.value = userLang
      } else if (locale.value !== 'en' && userLang === null) {
        // set user language from ui language
        try {
          await refreshCurrentUser()
          await nextTick() // wait for csrf token
          await api.call(schema.User.me_put, { body: { language: locale.value as UserInput['language'] } })
        } catch (error: any) {
          messageService.warn('Error setting user language: ', error.message)
        }
      }
    }
  }

  return { japaneseUser }
}

const isFetchingGeoLocation = ref(false)
const geoLocation = ref<{ country: string }>()

export function useGeolocation() {
  async function fetchLocation() {
    if (geoLocation.value || isFetchingGeoLocation.value) {
      return
    }

    try {
      isFetchingGeoLocation.value = true
      geoLocation.value = await api.call(schema.GeoLocationUser.geo_location)
      register({ geo_location: geoLocation.value.country })
    } catch (e) {
      geoLocation.value = { country: 'ZZ' }
    } finally {
      isFetchingGeoLocation.value = false
    }
  }

  return { geoLocation, fetchLocation }
}
