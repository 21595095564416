import { reactive, readonly, toRefs } from 'vue'

type Msg = {
  id?: number
  text: string
  level: 'success' | 'error' | 'warn' | 'info'
}

const data = reactive({
  messages: [] as Msg[],
  counter: 0,
})

export const messageService = readonly({
  ...toRefs(data),

  success(text: string, options = { timeout: 6000 }) {
    const msg: Msg = { text, level: 'success' }
    this._addMessage(msg, options)
    return msg
  },

  error(text: string, options = { timeout: 6000 }) {
    const msg: Msg = { text, level: 'error' }
    this._addMessage(msg, options)
    return msg
  },

  warn(text: string, options = { timeout: 6000 }) {
    const msg: Msg = { text, level: 'warn' }
    this._addMessage(msg, options)
    return msg
  },

  info(text: string, options = { timeout: 6000 }) {
    const msg: Msg = { text, level: 'info' }
    this._addMessage(msg, options)
    return msg
  },

  removeMessage(id: number | undefined) {
    data.messages = data.messages.filter((m) => m.id !== id)
  },

  _addMessage(msg: Msg, opts = { timeout: 6000 }) {
    msg.id = ++data.counter
    data.messages.push(msg)
    while (data.messages.length > 10) {
      data.messages.shift()
    }
    setTimeout(() => this.removeMessage(msg.id!), opts.timeout)
  },
})
