<template>
  <Teleport to="body">
    <Transition
      :enter-active-class="$style.progressEnterActive"
      :leave-active-class="$style.progressLeaveActive"
      :enter-from-class="$style.progressEnterFrom"
      :leave-to-class="$style.progressLeaveTo"
    >
      <div v-if="shown" :class="[$style.ProgressBar, value >= 1 && $style.full]">
        <div :class="$style.bar" :style="`width:${value * 100}%`"></div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'

const props = defineProps<{
  value: number
}>()

const shown = ref(false)

watchEffect(() => {
  if (props.value >= 1) {
    setTimeout(() => {
      shown.value = false
    }, 0)
  } else {
    shown.value = true
  }
})
</script>
<style module>
.ProgressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
}
.bar {
  height: 2px;
  transition: all var(--dodo-transition-duration, 0.15s);
  background: var(--dodo-color-primary);
}
.progressEnterActive,
.progressLeaveActive {
  transition: opacity var(--dodo-transition-duration, 0.15s) ease;
}

.progressEnterFrom,
.progressLeaveTo {
  opacity: 0;
}
</style>
