import { useRouter } from 'vue-router'
import { currentUser, refreshCurrentUser } from '..'

export function useRequireLogin() {
  const router = useRouter()

  async function checkAuthenticated() {
    if (!currentUser.value) {
      await refreshCurrentUser()
    }
    return currentUser.value
  }

  async function redirectToLogin() {
    localStorage.setItem('returnPath', router.currentRoute.value.fullPath)
    await router.push({ name: 'login' })
  }

  return { redirectToLogin, checkAuthenticated }
}
