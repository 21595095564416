import { default as mixpanelBrowser, Mixpanel } from 'mixpanel-browser'
import settings from './settings'

let mixpanel: Mixpanel | null

type MixpanelEvent =
  | 'User Logged In'
  | 'User Logged Out'
  | 'DeepThought Shared'
  | 'DeepThought Who To Follow Searched'
  | 'Extension Audience Copied'
  | 'Extension Audience Saved'
  | 'Extension Campaign Launched'
  | 'Extension AdAccount Access Granted'
  | 'XProTargeting Download Extension Clicked'
  | 'XProTargeting Event Material Clicked'
  | 'XProTargeting Example Clicked'
  | 'XProTargeting Feedback Clicked'
  | 'XProTargeting Form Submitted'
  | 'XProTargeting Insights Generated'
  | 'XProTargeting Marketing Material Opened'
  | 'XProTargeting Marketing Material Requested'
  | 'XProTargeting Targeting List Child Clicked'
  | 'XProTargeting Targeting List Opened'
  | 'XProTargeting Targeting List Parent Clicked'
  | 'XProTargeting Targeting List Previewed'
  | 'XProTargeting Targeting List Requested'
  | 'XProTargeting Autopilot Insights Generated'

type MixpanelRegisterProperty = 'geo_location' | 'id' | 'x_account_ids' | 'team_ids'

export function useMixpanel() {
  if (!mixpanel && settings.MODE === 'production' && settings.MIXPANEL_TOKEN) {
    mixpanelBrowser.init(settings.MIXPANEL_TOKEN, { track_pageview: 'url-with-path' })
    mixpanel = mixpanelBrowser
  }

  function track(event: MixpanelEvent, properties?: Record<string, any>) {
    if (mixpanel) {
      mixpanel.track(event, properties)
    }
  }

  function identify(id: string) {
    if (mixpanel) {
      mixpanel.identify(id)
    }
  }

  function reset() {
    if (mixpanel) {
      mixpanel.reset()
    }
  }

  function register(properties: Partial<Record<MixpanelRegisterProperty, any>>) {
    if (mixpanel) {
      mixpanel.register(properties)
    }
  }

  return { track, identify, reset, register }
}
