import { ref, watch, computed } from 'vue'
import { api, schema } from '../../api'
import { refreshCurrentUser } from '..'
import { useMixpanel } from '@/tracking'

const { track } = useMixpanel()

export function useLoginEmailForm() {
  const email = ref('')
  const code = ref('')
  const confirmingCode = ref(false)
  const loginConfirmed = ref(false)
  const codeInvalid = ref(false)
  const codeState = ref('')

  const emailSubmitted = computed(() => Boolean(codeState.value))

  async function confirmCode() {
    if (loginConfirmed.value) return
    confirmingCode.value = true
    // Need both codes to confirm login
    const body = { magic_code: codeState.value, confirm_code: code.value }
    try {
      await api.call(schema.MagicLoginLink.use, { body })
      track('User Logged In', { type: 'email' })
      await refreshCurrentUser()
      loginConfirmed.value = true
    } catch (e) {
      codeInvalid.value = true
      return
    } finally {
      confirmingCode.value = false
    }
  }

  async function submitEmail() {
    try {
      const result = await api.call(schema.MagicLoginLink.create, { body: { email: email.value } })
      codeState.value = result.magic_code
      return true
    } catch (e) {
      return false
    }
  }

  watch(code, async () => {
    if (code.value.length === 6) {
      await confirmCode()
    }
  })

  function resetEmailForm() {
    codeState.value = ''
    code.value = ''
    codeInvalid.value = false
    loginConfirmed.value = false
  }

  return { code, codeInvalid, emailSubmitted, confirmingCode, submitEmail, email, loginConfirmed, resetEmailForm }
}
