import { Plugin } from 'vue'
import settings from './settings'

// See: https://help.hotjar.com/hc/en-us/articles/115011639927-What-is-the-Hotjar-Tracking-Code-
// Install code was modified to fix typescript errors

declare global {
  interface Window {
    hj: any
    _hjSettings: any
  }
}

export const hotJarPlugin: Plugin = (app) => {
  if (settings.MODE === 'production') {
    const hjid = Number(settings.HOTJAR_ID)
    const hjsv = Number(settings.HOTJAR_SNIPPET_VERSION)

    if (!(hjid && hjsv)) return

    window.hj = (...args: any[]) => {
      window.hj.q.push(args)
    }
    window.hj.q = []
    window._hjSettings = { hjid, hjsv }

    const script = document.createElement('script')
    script.async = true
    script.src = `//static.hotjar.com/c/hotjar-${hjid}.js?sv=${hjsv}`
    document.head.appendChild(script)
  }
}
